/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import '../index.css';

function Landing() {
  return (
      <div className="container">
        <div className="landing">
            <div>
                <div className="scuba-steve">
                    <img src="/static/scuba-steve.png" alt="" />
                </div>
                <div className="content">
                    <div className="hydro-logo-and-tagline">
                        <img src="/static/logo-hydro-3d.png" alt="Hydro Logo" />
                    </div>
                    <div className="cta-and-socials">
                        <button className="stake blue cta expand">
                            <a href="https://wallet.keplr.app/#/secret/stake?modal=stake&validator=secretvaloper1nqke7wxsxw34ayacfjdhtmcz8anhn2l2kwwr75" target="_blank">Stake with us</a>
                        </button>
                        <div className="social-media-group">
                            <div>
                                <a href="https://discord.gg/eTd77sPSAk" target="_blank">
                                    <img className="social-media-icon expand first" src="/static/icon-discord.png?v2" alt="Discord Icon" />
                                </a>
                                <a href="https://t.me/hydrofinance" target="_blank">
                                    <img className="social-media-icon expand" src="/static/icon-telegram.png?v2" alt="Telegram Icon" />
                                </a>
                                <a href="https://twitter.com/DRO_DAO" target="_blank">
                                    <img className="social-media-icon expand last" src="/static/icon-twitter.png?v2" alt="Twitter Icon" />
                                </a>
                            </div>
                            <div>
                                <a href="https://www.instagram.com/dro_dao/" target="_blank">
                                    <img className="social-media-icon expand first" src="/static/icon-instagram.png?v2" alt="Instagram Icon" />
                                </a>
                                <a href="https://medium.com/@Hydro.Finance/update-on-hydro-finance-d0c81ddbadbe" target="_blank">
                                    <img className="social-media-icon expand" src="/static/icon-medium.png?v2" alt="Medium Icon" />
                                </a>
                                <a href="https://app.altermail.live/chat?join&c=l1fki7whrgsxwytbdf&t=54ud43lC&m=Jmc0E3PK" target="_blank">
                                    <img className="social-media-icon expand last" src="/static/icon-alter.png?v2" alt="Alter Icon" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
  )
}

export default Landing
